@use "../../config/variables.scss" as vars;

.Txt1 {
  font-family: vars.$Bold;
  font-size: 34px;
  text-align: center;
}
.Box1 {
  margin-top: 20px;
}
.Txt2 {
  font-family: vars.$Medium;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  line-height: 30px;
}
