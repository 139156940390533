@use "../../config/variables.scss" as vars;

.subscription-mainContainer {
  // height: 100vh;
  .tab-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 5px;
    background-color: #dadadb;
    border-radius: 9px;
  }
  .indicator {
    content: "";

    background: #ffffff;
    position: absolute;
    z-index: 9;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    transition: all 0.2s ease-out;
  }
  .tab {
    width: 30%;
    height: 28px;
    position: absolute;
    z-index: 99;
    outline: none;
    opacity: 0;
  }
  .tab_label {
    width: 130px;
    height: 30px;
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 0.75rem;
    opacity: 0.6;
    cursor: pointer;
  }
  .tabText {
    font-family: vars.$SemiBold;
    font-size: 12px;
  }
  .tab--1:checked ~ .indicator {
    width: 130px;
    height: 30px;
    position: absolute;
    left: 2px;
    transition: all 0.2s ease-out;
  }
  .tab--2:checked ~ .indicator {
    width: 130px;
    height: 30px;
    position: absolute;
    left: 114px;
    transition: all 0.2s ease-out;
  }
  .tab--3:checked ~ .indicator {
    width: 130px;
    height: 30px;
    position: absolute;
    right: 2px;
    transition: all 0.2s ease-in;
  }
}
.subscriptionScreen-totalAmountContainer {
  background-color: #434d50;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  .totalAmountContainer {
    font-size: 35px;
    font-family: "Medium";
    color: white;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .totalAmountText {
    font-size: 14px;
    color: #96a9af;
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
    font-family: "Regular";
  }
}
.subscriptionScreen-companyContainer {
  background-color: #ffffff;
  margin: 15px 0px;
  border-radius: 10px;
  display: flex;
  .textCompanyText {
    font-family: vars.$Regular;
    padding: 15px;
    width: 100%;
    .checkboxContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .totalAmountContainer {
    font-size: 44px;
    font-family: "Medium";
    color: #748287;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subscription-countBox {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #96a9af;
    border: 1px solid rgb(246, 243, 243);
    background-color: rgb(246, 243, 243);
  }
  .count-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-family: vars.$Medium;
  }
  .subscription-companyCount {
    font-size: 20px;
    margin: 0px 8px 0px 8px;
  }
  .subscription-btn-text {
    font-family: vars.$Medium;
    height: 45px;
    font-size: 17px;
  }
}

.subscription-plancontainer {
  background-color: #ff5353;
  color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  span {
    font-size: 28px;
    font-family: vars.$Medium;
  }
  p {
    font-size: 16px;
    font-family: vars.$Regular;
  }
}
.subscription-headtext {
  font-family: vars.$Medium;
  font-size: 22px;
}

.subscription-activePlancontainer {
  background-color: #e1f2ea;
  color: #34b628;
  padding: 25px;
  border-radius: 10px;
  span {
    font-size: 28px;
    font-family: vars.$Medium;
  }
  p {
    font-size: 16px;
    font-family: vars.$Regular;
  }
}
.subscription-planNameContainer {
  border-radius: 10px;
  background-color: #fbfbfb;
  padding: 20px;
  margin-top: 10px;
  .subscription-planTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: vars.$Regular;
    font-size: 14px;
  }
  .subscription-planName {
    padding-top: 4px;
    font-family: vars.$Medium;
    font-size: 17px;
  }
}

.subscription-text {
  font-family: vars.$SemiBold;
  font-size: 20px;
  color: #949494;
}
.subscription-text2 {
  font-family: vars.$Regular;
  font-size: 16px;
  color: #949494;
  margin-bottom: 10px;
}

.subscription-text3 {
  font-family: vars.$Bold;
  font-size: 16px;
  color: #34b628;
}
.background-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 21%);
  margin-bottom: 10px;
}
.background-div {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 15px;
  background-color: vars.$Primary;
  .head-text {
    font-family: vars.$Bold;
    color: #fff;
    font-size: 22px;
  }
  .text {
    font-family: vars.$Regular;
    color: #fff;
    font-size: 16px;
  }
}
