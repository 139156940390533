@use "../../config/variables.scss" as global;
#payment-form {
  width: 500px;
}
.paymentCard-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .success-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  .success-text {
    font-weight: bold;
  }
  .success-paragraph {
    margin-bottom: 20px;
    text-align: center;
  }
  .payment-circle {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #5CB85C;
    animation: bounce 2s infinite alternate;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes bounce {
    0% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(0.8);
    }
  }