@use "../../config/variables.scss" as vars;
.footer-Box1 {
  // height: 50vh;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 568px) {
    padding: 30px;
  }
}
.footer-Img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 70px;
  }
  @media (max-width: 568px) {
    height: 40px;
  }
 
}
.footer-Txt2 {
  font-family: vars.$SemiBold;
  font-size: 32px;
  text-align: center;
}
.footer-Txt1 {
  font-family: vars.$Medium;
  font-size: 18px;
  text-align: center;
}
