@use "../../config/variables.scss" as vars;

.order-box1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 75vh;

    @media (min-width: 992px) {
        border-left: 1px solid rgb(222, 222, 222);
    }
}

.order-box2 {
    height: 84%;
    padding: 15px;
    overflow: scroll;
}

.order-box3 {
    border-top: 1px solid rgb(222, 222, 222);
    padding: 14px;
}

.order-table {
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.order-txt1 {
    font-family: vars.$Regular;
    color: grey;
    font-size: 14px;
}

.order-txt2 {
    text-align: right;
    font-family: vars.$Medium;
}

.order-ProductItem {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    border: 1px solid rgb(236, 236, 236);
    overflow: hidden;
}

.order-ProductItemBox {
    padding: 10px;
    border-top: 1px solid rgb(222, 222, 222);
}

.order-ProductItemtxt1 {
    font-family: vars.$Regular;
    font-size: 14px;
}

.order-ProductItemtxt2 {
    font-family: vars.$SemiBold;
    font-size: 14px;
}

.order-ProductItemtxt3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: red;
}

.ProductItemImg2 {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.ProductItemtxt1 {
    font-family: vars.$Regular;
    font-size: 14px;
}

.ProductItemtxt2 {
    font-family: vars.$SemiBold;
    font-size: 14px;
}

.ProductItemtxt3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.ProductItemImg {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.order-ListItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding-bottom: 10px;
    margin-top: 10px;

    &:last-child {
        border-bottom: 0px;
    }
}

.order-ListItemtxt {
    margin-right: 10px;
    font-family: vars.$SemiBold;
    font-size: 14px;
}

.order-ListItemtxt1 {
    font-family: vars.$Medium;
    font-size: 15px;
}

.order-ListItemtxt2 {
    font-size: 13px;
    color: gray;
}