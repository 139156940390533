@use "../../config/variables.scss" as vars;
.support-txt1 {
  font-family: vars.$Medium;
  font-size: 14px;
  color: grey;
}

.support-txt2 {
  font-family: vars.$Bold;
  font-size: 38px;
  color: vars.$Secondary;
}
.support-Btn {
  font-size: 12px;
  font-family: vars.$Medium;
}
.support-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.support-Box1 {
  width: 500px;
  height: 500px;
}
