@use "../../config/variables.scss" as vars;

.loginScreen-Box2 {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50%;
    height: 150px;
    object-fit: contain;
  }
}
.login_checkbox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.login_input {
  height: 45px;
}
.login_btn {
  height: 50px;
  border-radius: 10px;
}
.login_box {
  width: 80%;
  height: 100%;
  border-radius: 70px;
  // background: linear-gradient(to bottom right, #fda308, #ffda9b);
  background-image: url('../../assets/images/bg.png');;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  img {
    width: 100%;
    object-fit: contain;
  }
  p {
    font-family: vars.$ExtraBold;
    font-size: 35px;
    color: #fff;
    margin-bottom: 5px;
  }
  .login_text{ 
    color: #fff;
    font-family: vars.$Regular;
  }
}
.bordered-div {
  width: 225px;
  height: 50px;
  border-top: 3px solid #fff;
  border-radius: 35%;
  padding-right: 20px;
  margin-left: 160px;
}

.login_head {
  font-family: vars.$ExtraBold;
  font-size: 32px;
}
.login_subhead {
  font-family: vars.$Medium;
  font-size: 13px;
  color: vars.$gray;
  margin-bottom: 35px;
}
.footer_text {
  font-family: vars.$Bold;
  font-size: 12px;
  color: vars.$gray;
  display: flex;
}
