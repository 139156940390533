@use "./config/variables.scss" as vars;

.Table-Txt {
    font-family: vars.$Medium;
    font-size: 22px;
  }

  .paidStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    width: fit-content;
  }