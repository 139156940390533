@use "../../config/variables.scss" as vars;

.header-Box1 {
  height: 70px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}
.header-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 28px;
  color: "#000";
  @media (max-width: 767.5px) {
    font-size: 24px;
  }
}
.header-Txt2 {
  font-family: vars.$SemiBold;
  font-size: 12px;
  color: "#000";
  cursor: pointer;
  @media (max-width: 767.5px) {
    font-size: 12px;
  }
  &:hover {
    border-bottom: 1.5px solid vars.$Primary; // Red border on hover
  }
}
.header-Box2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 767.5px) {
    display: none;
  }
}
.header-Box4 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.header-Box-mobile2 {
  display: none;
  @media (max-width: 767.5px) {
    display: block;
    display: flex;
    justify-content: end;
    margin-right: 5px;
  }

}
.header-Logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}
