//font
$Bold: "Bold";
$ExtraBold: "ExtraBold";
$SemiBold: "SemiBold";
$Medium: "Medium";
$Regular: "Regular";

//COLOR
$Primary: #ffbc49;
$Secondary: #36b372;
$gray: #d1d1d1;
