@use "../../config/variables.scss" as vars;

.subscription {
  .text-1 {
    font-family: vars.$Bold;
    font-size: 18px;
  }
  .text-2 {
    font-family: vars.$Regular;
    font-size: 14px;
  }
  .day-container {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    background-color: #ffeac4;
    display: flex;
    .card {
      padding: 8px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0px;
    }
    .card-2 {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #ffbc49;
    border-radius: 6px;
    color: white;
    font-family: vars.$ExtraBold;
    font-size: 18px;
  }
  .trade-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #EEF0F2;
    border-radius: 6px;
  }
}
