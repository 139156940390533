@use "../../config/variables.scss" as vars;

.company {
  .head {
    font-family: vars.$Bold;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .company-edit {
    position: absolute;
    bottom: 0px;
    background-color: rgb(0 0 0 / 48%);
    border-radius: 0px 0px 150px 150px;
    width: 220px;
    height: 110px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company-picture-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .company-picture {
      position: relative;
      display: flex;
      justify-content: center;
      width: 220px;
      height: 220px;
    }
  }

  .company-text-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      border-bottom: 1px solid #d3d3d3;
      border-radius: 0;
    }
  }

  .company-txt1 {
    font-family: vars.$Medium;
    font-size: 20px;
    display: flex;
    flex-direction: row;
  }

  .company-imgstaff {
    border-radius: 100%;
    cursor: pointer;
    background-color: #fff;
    padding: 5px;
    border: 1px solid rgb(217, 217, 217);
    width: 100%;
    display: block;
  }
}
